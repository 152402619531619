$default_fonts: 'Poppins', sans-serif;
$secondary_fonts: 'Raleway', sans-serif;

$primary_color: #d33f8d;
$secondary_color: #134DB3;
$dark_color: #333333;
$light_color: #e6e6e6;

$default_radius: .2rem;

$default_transition: all 0.2s ease-in;

$fonts: '../fonts';
$imgs: './imgs';


$danger:     #EA2027;
$warning:  #ffc107;
$success:   #28a745;
