.alert{
  &.with-close-btn{
    position: relative;
    padding-right: 2.2rem;
    .close{
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}
