.accordion-tabs{
  .accordion-tab{
    position: relative;
    border-top: 1px solid rgba($dark_color, 0.2);
    &-content{
      padding: calc(1vh + 0.2vw + 5px) 0;
    }
    &-trigger{
      background: none;
      padding: calc(0.2vh + 15px) 0;
      @include font-size(1.4);
      width: 100%;
      display: block !important;
      height: auto !important;
      border: none;
      text-align: left;
      color: $primary_color;
      position: relative;
      font-weight: bold;
      &:after{
        font-family: "Font Awesome 5 Pro";
        content: "\f105";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        font-weight: 900;
        @include font-size(2);
      }
    }
    &.expanded,&.EXPANDED,&.active{
      padding-bottom: 30px;
      .accordion-tab-trigger{
        &:after{
          content: "\f106";
        }
      }
    }
  }
}
