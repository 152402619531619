html,body{
  height: 100%;
}
body{
  font: $default_fonts;
  @include font-size(1.4);
}

p{ line-height: 1.4; }
button{
  outline: none !important;
  cursor: pointer;
}
a,input{
  outline:none !important;
}
a{
  transition: $default_transition;
  color:$secondary_color;
}
a:hover{
  color: rgba($primary_color, 0.8);
}
img{
  max-width:100%;
  height:auto;
}
ul,ol{
  padding-left: 20px;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
  margin:25px 0;
  font-weight: bold;
  line-height:1;
  color: $dark_color;
  font-family: $secondary_fonts;
}


header{
  h1,h2,h3,h4,h5,h6{
    margin-top: 0;
  }
}
h1,.h1{
  @include font-size(4);
}
h2,.h2{
  @include font-size(3.6);
}

h3,.h3{
  @include font-size(3);
}
h4,.h4{
  @include font-size(2.6);
}
h5,.h5{
  @include font-size(2);
}
h6,.h6{
  @include font-size(1.6);
}

select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.container{
  width: 100%;
  max-width: 1800px;
}
/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }


/*third party*/
.intl-tel-input.allow-dropdown .selected-flag{
  outline: none !important;
  box-shadow: none !important;
}

.external_json_view-code{
  > div:nth-child(odd){
    background: rgba(#000,0.02);
  }
}

.smaller{
  line-height: 1;
  @include font-size(0.4);
}