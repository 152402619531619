.theme-text-gradient{
  background: -webkit-linear-gradient($secondary_color, $primary_color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-400{
  font-weight: 400 !important;
}
.text-500{
  font-weight: 500 !important;
}
.text-600{
  font-weight: 600 !important;
}
.text-700{
  font-weight: 700 !important;
}
.text-800{
  font-weight: 800 !important;
}
.text-900{
  font-weight: 900 !important;
}
.color-inherit{
  color: inherit !important;
}
.color-light{
  color: rgba($dark_color, 0.5);
}
.color-primary{
  color: $primary_color !important;
}
.color-secondary{
  color: $secondary_color !important;
}
.color-danger{
  color: $danger !important;
}
.text-underline{
  text-decoration: underline !important;
}
.text-borderline{
  border-bottom: 1px solid rgba($dark_color, 0.5);
  text-decoration: none !important;
  &.color-primary{
    border-color: $primary_color !important;
  }
  &.color-secondary{
    border-color: $secondary_color !important;
  }
}
a.text-borderline{
  &:hover{
    border-color: $primary_color;
  }
}
.bg-white{
  background: #fff;
}
.pseudo-link{
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -99999;
}

.fonts-secondary{
  font-family: $secondary_fonts;
}
.fonts-primary{
  font-family: $default_fonts;
}

.gradient-bg{
  overflow: hidden;
  position: relative;
  background: #d33f8d;
  background: -moz-linear-gradient(top,  $primary_color 0%, $secondary_color 100%);
  background: -webkit-linear-gradient(top,  $primary_color 0%, $secondary_color 100%);
  background: linear-gradient(to bottom,  $primary_color 0%, $secondary_color 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d33f8d', endColorstr='#134db3',GradientType=0 );
  &:after{
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    bottom:-10%;
    //background: url("#{$imgs}/bg-shape.png") left bottom no-repeat;
    z-index: 0;
  }
}
.big{
  font-size: 120%;
}

.no-wrap{
  white-space: nowrap !important;
}

.invisible{
  opacity: 0;
}
.invisible-md{
  @media (min-width: 768px){
      opacity: 0;
  }
}

.spinner {
  width: 70px;
  text-align: center;
  margin: auto;
  display: none;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: $secondary_color;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}
