//mixins
@mixin transition-mix($property: all, $duration: 0.2s, $timing: linear, $delay: 0s) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
  transition-delay: $delay;
}

@mixin position-absolute($top: null, $left: null, $right: null, $bottom: null) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

//basic variables
$theme-font-color: #2c2c2c;
//multisteps variables
$color-secondary: #6c757d;
$color-primary: #007bff;

$btn-offset-vert: 20px;
$btn-circle-decor-dimensions: 13px;


.multi-steps-canvas {

  .header__btn {
    @include transition-mix;

    padding: 10px 20px;
    display: inline-block;
    margin-right: 10px;

    background-color: #fff;
    border: 1px solid $theme-font-color;
    border-radius: 3px;

    cursor: pointer;

    outline: none;

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &.js-active {
      color: #fff;

      background-color: $theme-font-color;
    }
  }

  .header__title {
    margin-bottom: 30px;

    font: {
      size: 2.1rem;
    }
  }

  //multisteps progress styles
  .multisteps-component__progress {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    padding: 10px 0;
  }

  .multisteps-component__progress-btn {
    @include transition-mix($duration: .15s);
    position: relative;
    padding-top: $btn-offset-vert;
    color: rgba($color-secondary, .7);
    text-indent: -9999px;
    border: none;
    background-color: transparent;
    outline: none !important;
    cursor: pointer;
    @include font-size(0.9);
    font-weight: 600;
    @media (min-width: 500px) {
      text-indent: 0;
    }


    //circle decoration
    &:before {
      @include position-absolute($top: 0, $left: 50%);

      display: block;
      width: $btn-circle-decor-dimensions;
      height: $btn-circle-decor-dimensions;

      content: '';

      transform: translateX(-50%);

      transition: all .15s linear 0s,
      transform .15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;

      border: 2px solid currentColor;
      border-radius: 50%;
      background-color: #fff;

      box-sizing: border-box;

      z-index: 3;
    }

    //line decoration
    &:after {
      @include position-absolute($top: math.div($btn-offset-vert, 4), $left: calc(-50% - #{$btn-circle-decor-dimensions} / 2));
      @include transition-mix($duration: .15s);

      display: block;
      width: 100%;
      height: 2px;
      content: '';
      background-color: currentColor;
      z-index: 1;
    }

    &:first-child {
      &:after {
        display: none;
      }
    }

    //active styles
    &.js-active {
      color: $color-primary;

      &:before {
        transform: translateX(-50%) scale(1.2);

        background-color: currentColor;
      }
    }
  }

  //multisteps form styles
  .multisteps-component__wrap {
    //overflow: hidden;
    position: relative;
  }

  //multisteps panels styles
  .multisteps-component__panel {
    @include position-absolute($top: 0, $left: 0);

    width: 100%;
    height: 0;

    opacity: 0;
    visibility: hidden;

    //active panels
    &.js-active {
      height: auto;

      opacity: 1;
      visibility: visible;
    }

    //scaleOut animation
    &[data-animation="scaleOut"] {
      transform: scale(1.1);

      &.js-active {
        @include transition-mix;

        transform: scale(1);
      }
    }

    //slideHorz animation
    &[data-animation="slideHorz"] {
      left: 50px;

      &.js-active {
        @include transition-mix($duration: .25s, $timing: cubic-bezier(0.2, 1.13, 0.38, 1.43));

        left: 0;
      }
    }

    //slideVert animation
    &[data-animation="slideVert"] {
      top: 30px;

      &.js-active {
        @include transition-mix();

        top: 0;
      }
    }

    //fadeIn animation
    &[data-animation="fadeIn"] {


      &.js-active {
        @include transition-mix($duration: .3s);
      }
    }

    //scaleOut
    &[data-animation="scaleIn"] {
      transform: scale(.9);

      &.js-active {
        @include transition-mix;

        transform: scale(1);
      }
    }
  }

  .has-errors .multistep-general-error{
    display: block;
  }

}


.blinking-error{
  animation:blinkingText 1s infinite;
}
@keyframes blinkingText{
  0%{     color: $danger;  }
  60%{    color: rgba(#000,0.1); }
  100%{   color: $danger; }
}


.blinking-text{
  animation:blinkingText 1s infinite;
}
@keyframes blinkingText{
  0%{     color: inherit;  }
  60%{    color: transparent; }
  100%{   color: inherit; }
}


