.dashboard-cards{
  text-align: center;
  h1{
    color: #fff;
    margin-bottom: calc(5vh + 15px);
    @include font-size(6);
  }
  .dashboard-card {
    background: #fff;
    padding: calc(2vh + 2vw + 15px) calc(10px + 2.5vw + 1vh);
    border-radius: $default_radius;
    text-align: center;
    margin: 5px;
    > a {
      color: #000;
      font-weight: bold;
      text-indent: -999px;
      display: block;
      text-align: center;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    } 
    position: relative; 
    &-inner{
      strong{
        margin-top: 20px;
        display: block;
      }
    }
    &-badge {
      position: absolute;
      top: 1vw;
      left: 0;
      text-align: center;
      width: 100%;
      @include font-size(0.5);
      .fal, .far, .fab{
        @include font-size(0.8);
      }
      font-weight: 700;
      &.success{
        color: $success;
      }
      &.danger{
        color: $danger;
      }
      &.warning{
        color: $warning;
      }
    }
    svg{
      width: calc(5vh + 5vw + 20px);
      height: calc(5vh + 5vw + 20px);
    }
  }
}
