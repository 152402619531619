.page{
  min-height: 100vh;
  .page-content{
    background: #fff;
    padding: calc(2vh + 2vw + 15px) calc(10px + 2.5vw + 1vh);
    border-radius: $default_radius;
    margin: calc(20px + 5vh) auto;
  }
  .page-icon{
    @include font-size(5);
  }
  .container{
    position: relative;
    z-index: 20;
  }
  &.page-new-tour{
    .page-content{
      background: #fff;
      padding: calc(0.5vh + 0.5vw + 15px) calc(10px + 1vw + 1vh);
      border-radius: $default_radius;
    }
  }
  &-login{
    .page-content{
      background: #fff;
      padding: calc(3vh + 1vw) 10px;
      text-align: center;
      @media (max-width: 767px){
        padding: 30px 15px 0;
        border-radius: $default_radius $default_radius 0 0;
      }
      h1{
        .fal,.fas,.far{
          vertical-align: top;
        }
        @media (min-width: 1800px){
          font-size: 150px;
        }
        @include font-size(17);
        line-height: 0.78;
        .fal,.far,.fab,.fas{
          font-size: 70%;
        }
        @media (max-width: 767px){
            @include font-size(8);
        }
        > span{
          white-space: nowrap;
          display: block;
        }
      }
    }
  }
  &.narrow-page{
    .container{
      transition: $default_transition;
      max-width: 1150px;
      @media (max-width: 1800px){
        max-width: 62%;
      }
      @media (max-width: 1199px){
        max-width: 96%;
      }
    }
  }
  .page-form-wrap{
    max-width: 60%;
    @media (max-width: 1200px) {
      max-width: 60vw;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
  .page-form{
    background: #fff;
    padding: calc(2vh + 2vw + 15px) calc(10px + 2.5vw + 1vh);
    border-radius: $default_radius;
    @media (max-width: 767px){
      border-radius: 0 0 $default_radius $default_radius;
    }
    .form-options-separator{
      margin: calc(2vh + 0.5vw + 15px) 0 15px;
      text-align: center;
      color: rgba($dark_color, 0.5);
      font-family: $secondary_fonts;
    }
    .login-social-options{
      > span{
        opacity: 1 !important;
      }
      .btn {
        margin-bottom: calc(0.5vh + 10px);
        i{
          margin-right: 10px;
          min-width: 30px;
        }
      }
    }
  }
  .full-page-row{
    padding-top: 15px;
    padding-bottom: 15px;
    @media (min-width: 768px){
        min-height: 100vh;
    }
  }
}

.text-widget{
  color: rgba($dark_color, 0.6);
}

.page-login{
  .page-content{
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
}

.map-canvas{
  width: 100%;
  height: calc(25vh + 5vw + 100px);
  min-height: calc(25vh + 5vw + 100px);
}

.alert.bg-danger{
  color: #fff;
}