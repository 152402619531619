.form-control{
  border: none;
  box-shadow: none !important;
  background-color: $light_color !important;
  border-radius: $default_radius;
  position: relative;
  z-index: 10;
  line-height: 1;
  &.error{
    border-color: red;
  }
  &::placeholder{
    font-family: $secondary_fonts;
  }
  &:focus + .after-control{
    .form-control-icon{
      width: 60px;
      color: $primary_color;
    }
  }
  &:focus + .focus-effect, &:focus + .after-control > .focus-effect{
    -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
    animation: anim-shadow 0.5s ease-in-out forwards;
  }
    &:focus:not(.popover-only-on-error) + .after-control{
      .popover{
        visibility: visible;
        opacity: 1;
      }
    }
    &.error{
      box-shadow: 0 0 2px 2px rgba($danger, 0.7) !important;
      &.popover-only-on-error:focus + .after-control{
        .popover{
          visibility: visible;
          opacity: 1;
        }
      }
    }
  &.error-uppercase{
    & + .after-control .error-uppercase{
      display: list-item;
    }
  }
  &.error-number{
    & + .after-control .error-number{
      display: list-item;
    }
  }
  &.error-length{
    & + .after-control .error-length{
      display: list-item;
    }
  }
  &.error-special-characters{
    & + .after-control .error-special-characters{
      display: list-item;
    }
  }
  &.error-prohibited-characters{
    & + .after-control .error-prohibited-characters{
      display: list-item;
    }
  }
  &:focus, &:not(:placeholder-shown){
    & + .after-control{
      .floating-label{
        transform: translate(1%, -165%) scale(.8);
      }
    }
  }
}
input.form-control {
  &[type='text'],&[type='tel'],&[type='url'],&[type='date'],&[type='password'],&[type='email'],&[type='search']{
    height: calc(1.2vh + 1.6vw + 25px);
    padding: 0 20px;
    .floating-label-group &{
      height: calc(1.2vh + 1.6vw + 25px);
      padding: 15px 5px 0 20px;
    }
  }
}
.form-control,.custom-select{
  @include font-size(1.5);
  color: #686868;
}
textarea.form-control{
  padding: 20px;
  height: calc(5vh + 1.6vw + 60px);
  .floating-label-group &{
    padding: 25px 10px 10px 20px;
  }
}
.custom-select{
  height: calc(1.2vh + 1.6vw + 25px);
  line-height: 1.5 !important;
  option:disabled {
    color: #000;
    color: rgba(0, 0, 0, 0.3);
  }
}
.post-submit-message{
  display: none;
}
.form-group,.input-group{
  margin-bottom: 1rem;
  position: relative;
  .input-group-append{
    .btn{
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .popover{
    .arrow{
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .after-control{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .focus-effect{
    position: absolute;
    border-radius: calc(#{$default_radius} + 2px);
    top: 0;
    left: 0;
    opacity: 0;
    /*z-index: -1;*/
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 0 ;
    color: rgba($primary_color, 0.8);
  }
  &.btn-wrap{
    margin-top: calc(2vh + 15px);
  }
  &.with-icon{
    .form-control{
      padding-left: 65px !important;
    }
    .form-control-icon{
      width: 65px;
      text-align: center;
      position: absolute;
      left: 0;
      top: 50%;
      z-index: 10;
      transition: $default_transition;
      transform: translate(0, -50%);
      @include font-size(3);
      line-height: 1;
      vertical-align: middle;
      margin-top: -2px;
      color: rgba($dark_color, 0.5);
    }
    .floating-label{
      left: calc(65px - 0.76em);
    }
  }
  &.floating-label-group{
    .form-control{
      &::placeholder{
        opacity: 0;
        color: transparent;
      }
    }
  }
  .floating-label{
    transition: transform .25s, opacity .25s ease-in-out;
    transform-origin: 0 0;
    opacity: 1;
    font-family: $secondary_fonts;
    color: rgba($dark_color, 0.4);
    line-height: 0.8;
    vertical-align: middle;
    padding: 0 0.76em;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 8px;
    user-select: none;
    z-index: 10;
    margin: 0;
    cursor: text;
  }
  textarea{
    & + .after-control .floating-label{
      top: 20px;
      transform: translate(0);
    }
    &:focus, &:not(:placeholder-shown){
      & + .after-control{
        .floating-label{
          transform: translate(1%, -95%) scale(.8);
        }
      }
    }
  }
  &.style-secondary{
    .floating-label{
      display: none;
    }
    .focus-effect{
      display: none;
    }
    .form-control{
      background: #fff !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      color: $dark_color !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      &.error{
        border-color: $danger;
      }
    }
  }
  &.custom-image-selector{
    background-size: cover;
    background-position: center center;
    height: calc(5vh + 140px);
    margin-bottom: 30px;
    border: 1px solid rgba(#000, 0.1);
    border-radius: $default_radius;
    position: relative;
    input{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
    > label{
      position: absolute;
      bottom: 3px;
      left: 3px;
      right: 3px;
      border-radius: 0 0 3px 3px;
      padding: 10px 5px;
      background: #fff;
      margin: 0;
      line-height: 1;
      color: #686868;
      @include font-size(0.8);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-shadow: 0 0 4px 2px rgba(#000, 0.1);
    }
    > img{
      position: absolute;
      z-index: -1;
      visibility: hidden;
    }
    .after-control{
      &:before,&:after{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -100%);
        overflow: hidden;
        width: 0;
        height: 0;
        transition: $default_transition;
      }
      &:before{
        content: "";
        background: $success;
        border-radius: 50%;
      }
      &:after{
        text-align: center;
        line-height: 40px;
        font-family: "Font Awesome 5 Pro";
        content: "\f00c";
        color: #fff;
        font-size: 14px;
      }
    }
    input:checked + .after-control{
      &:before,&:after{
        width: 40px;
        height: 40px;
      }
    }
  }
  &.custom-radio-control{
    position: relative;
    color: #686868;
    margin: 0;
    .radio-control-list &{
      border-top: 1px solid rgba(#686868,0.08);
    }
    input{
      width: 0 !important;
      height: 0 !important;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      &:checked + .after-control label{
        &:before{
          background: $success;
          border-color: $success;
        }
        &:after{
          color: #fff;
          width: 22px;
        }
      }
    }
    .after-control{
      position: relative;
      padding: 10px 15px;
      padding-left: 65px;
      .focus-effect{
        z-index: -1;
      }
      label{
        position: relative;
        cursor: pointer;
        line-height: 1.5;
        margin: 0;
        &:after{
          transition: $default_transition;
          overflow: hidden;
          position: absolute;
          left: -50px;
          width: 0;
          top: 50%;
          font-size: 12px;
          line-height: 25px;
          transform: translate(0, -50%);
          text-align: center;
          color: $secondary_color;
          font-family: "Font Awesome 5 Pro";
          content: "\f00c";
        }
        &:before{
          transition: all 0.6s ease-in;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          content: "";
          border: 2px solid rgba(#000,0.4);
          display: block;
          position: absolute;
          left: -50px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }
}
form{
  button[type="submit"]{
    position: relative;
    .btn-icon{
      display: inline-block;
      width: 0;
      transition: $default_transition;
      visibility: hidden;
    }
  }
  &.loading{
    pointer-events: none;
    opacity: 0.9;
    button[type="submit"]{
      .btn-icon{
        visibility: visible;
        width: 50px;
      }
      opacity: 0.7;
    }
  }
}


@-webkit-keyframes anim-shadow {
  from {
    opacity: 1;
  }
  to {
    box-shadow: 0 0 60px 20px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  from {
    opacity: 1;
  }
  to {
    box-shadow: 0 0 60px 20px;
    opacity: 0;
  }
}

#general-error{
  .alert{
    color: #fff;
    padding: calc(0.1vh + 5px) calc(0.1vw + 10px);
    border-radius: $default_radius;
    margin-bottom: 15px;
    &.alert-danger{
      background: $danger;
      border: none;
    }
  }
}

.multi-steps-forms{
  .multi-step{
    display: none;
    &.active{
      display: block;
    }
  }
}
.intl-tel-input{
  position: relative;
  z-index: 25;
  display: block !important;
  input::placeholder{
    opacity: 1;
    color: rgba(51, 51, 51, 0.4);
  }
  .selected-flag{
    background: rgba(#000,0.05);
    border: none;
  }
  &.allow-dropdown input, &.allow-dropdown input[type="text"], &.allow-dropdown input[type="tel"]{
    padding-left: 65px !important;
  }
}

.custom-checkbox-control{
  position: relative;
  margin-bottom: 15px;
  font-family: $default_fonts;
  @include font-size(1.2);
  color: rgba($dark_color, 0.6);
  padding-left: 45px;
  &.small{
    @include font-size(0.8);
  }
  input{
    position: absolute;
    left: 0;
    top: 1px;
    background: rgba($dark_color,0.1);
    -webkit-appearance: none;
    height: 20px;
    width:  30px;
    border: 1px solid rgba($dark_color, 0.1);
    box-shadow: -7px -7px 15px rgba(255,255,255,0.65), 7px 7px 15px rgba(70,70,70,0.12), inset -7px -7px 15px rgba(255,255,255,0.65), inset 7px 7px 15px rgba(70,70,70,0.12);
    outline: none;
    cursor: pointer;
    border-radius: 40px;
    transition: $default_transition;
    vertical-align: middle;
    &:before{
      content: "";
      width:  14px;
      height:  14px;
      position: absolute;
      left: 2px;
      top: 50%;
      transform: translate(0, -50%);
      border-radius: 50%;
      background-color: #bababa;
      transition: $default_transition;
    }
    &.error{
      &:before{
        background-color: red !important;
      }
    }
    &:checked{
      &:before{
        left: 100%;
        transform: translate(calc(-100% - 2px), -50%);
        background: $success;
      }
    }
  }
  label{
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    cursor: pointer;
    span{
      vertical-align: middle;
    }
  }
}


.upload-picture{
  margin-bottom: 20px;
  text-align: center;
  @include font-size(0.9);
  color: #3094e5;
  font-family: $default_fonts;
  position: relative;
  .current-image{
    width: calc(3.4vh + 2.8vw + 35px);
    height: calc(3.4vh + 2.8vw + 35px);
    margin: auto;
    overflow: hidden;
    border-radius: 50%;
    img{
      max-width: 100%;
    }
  }
  .upload-action{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(3.4vh + 8vw + 40px);
    height: 100%;
    z-index: 999;
    top: 0;
    background: none;
    border: none;
    opacity: 0;
  }
}

.file-selector-wrap{
  position: relative;
  label{
    margin: 0;
    cursor: pointer;
  }
  input{
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.react-tags__search-input{
  min-width: 100px;
}
