@use "sass:math";

@mixin font-size($sizeValue: 1.6) {
  font-size: #{$sizeValue * 10 + px};
  font-size: calc(#{math.div($sizeValue, 4)}vw + #{math.div($sizeValue,3)}vh + 8px);
}

@mixin letter-spacing-ps($val) {
  letter-spacing: ( $val / 1000 ) * 1em;
}

