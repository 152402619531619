.btn{
  border-radius: $default_radius;
  box-shadow: none !important;
  outline: none !important;
  padding: calc(0.2vh + 0.15vw + 5px) 10px;
  font-weight: 600;
  &.btn-min-width{
    min-width: 165px;
  }
  &.btn-sync{
    background: $secondary_color;
    color: #fff;
    position: fixed;
    right: 10px;
    border-radius: 0 0 40px 40px;
    top: 0;
    z-index: 99;
    @include font-size(2);
    padding: 25px 15px;
    .loading-state, .loaded-state{
      display: none;
    }
    .normal-state{
      display: block;
    }
    &.loading{
      background: #000;
      cursor: progress;
      .normal-state, .loaded-state{
        display: none;
      }
      .loading-state{
        display: block;
      }
    }
    &.loaded{
      background: #4AD395;
      cursor: not-allowed;
      .normal-state, .loaded-state{
        display: none;
      }
      .loaded-state{
        display: block;
      }
    }
  }
  .btn-text{
    display: inline-block;
  }
  &.btn-shadow{
    background: #fff;
    border-radius: $default_radius;
    transition: $default_transition;
    padding: 10px 20px;
    box-shadow: 0 0 10px 2px rgba(#000, 0.1) !important;
    border: 1px solid $primary_color;
    position: relative;
    color: $primary_color;
    &.highlight{
      background: $primary_color;
      color: #fff;
    }
    &.with-icon{
      i{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        @include font-size(3);
      }
    }
  }
  &.loading{
    pointer-events: none;
    .btn-text{
      display: none
    }
    .spinner{
      display: inline-block;
    }
  }
  .fab{
    font-weight: normal;
  }
  &.btn-wide{
    padding: calc(0.2vh + 0.15vw + 5px) 30px;;
  }
  &.btn-dark, &.btn-primary, &.btn-secondary{
    .spinner{
      > div{
        background: #fff;
      }
    }
  }
  &.btn-dark{
    background: $dark_color;
    &.less-dark{
      background: #7f8c8d;
    }
    &:hover{
      background: $secondary_color;
      border-color: $secondary_color;
    }
  }
  &.btn-primary{
    background: $primary_color;
    border: none;
    &:active{
      background: $secondary_color;
    }
    &:hover{
      background: $secondary_color;
    }
  }
  &.btn-secondary{
    background: $secondary_color;
    border: none;
    &:active{
      background: $dark_color;
    }
    &:hover{
      background: $dark_color;
    }
  }
  &.btn-md{
    padding: calc(0.8vh + 0.2vw + 8px) 10px;
  }
  &.btn-apple{
    background: #000;
    color: #fff;
  }
  &.btn-facebook{
    color: #fff;
    background: #3b5998;
  }
  &.btn-linkedin{
    color: #fff;
    background: #027bb6;
  }
}

.btn-logout{
  position: relative;
  display: inline-block;
  background: none;
  color: rgba(#000, 0.4);
  border: none;
  bottom: calc(-1.5vh - 5px);
  padding: 0;
  border-bottom: 1px solid rgba(#000,0.4);
  transition: $default_transition;
  text-decoration: none !important;
  &:hover{
    color: $danger;
    border-color: $danger;
  }
}

.map-btn{
  position: relative;
  z-index: 99;
  background: $success;
  color: #fff;
  border-radius: 3px;
  border: 3px solid rgba(#000,0.3);
  padding: 5px;
  margin-left: 11px;
  top: -100px;
  margin-bottom: -40px;
}
