.tour-title{
  position: relative;
  transition: all 0.35s ease-in;
  padding-top: 80px;
  > h1, .h1{
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.2s ease-in;
  }
  &.done{
    padding-top: 0;
    button[type='submit'] {
      display: none;
    }
    > h1,.h1{
      top: -50px;
      opacity: 0;
      visibility: hidden;
    }
    .form-control{
      font-weight: 900;
      @include font-size(2.6);
    }
  }
}

.image-viewport{
  position: relative;
  &.loading{
    .spinner{
      display: block;
    }
    img{
      opacity: 0.3;
    }
  }
}

.pseudo-next{
  @media (min-width: 768px){
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 99;
    margin-bottom: 24px;
  }
}


.closeAndGoBack{
  position: absolute;
  right: 0;
  top: 0;
  @include font-size(2);
  background: none;
  border: none;
  display: inline-block;
  padding: 5px 10px;
  color: $dark_color;
}
